import { Component, Input, Output, EventEmitter, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-edit-notes",
  templateUrl: "./edit-notes.component.html",
  styleUrls: ["./edit-notes.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EditNotesComponent {
  @Input() set notes(notes: string) {
    this.noteContent = notes;
  }

  @Output() notesChange = new EventEmitter<String>();
  @Output() closePopover = new EventEmitter<void>();

  noteContent: string = "";

  handleMouseDown(event: MouseEvent): void {
    event.preventDefault();
  }

  saveNote(): void {
    this.notesChange.emit(this.noteContent);
    this.closePopover.emit();
  }
}
