export const UNIT_FOR_DISTANCE = { Metres: "Meters", Km: "Kilometers", Miles: "Miles" };
export const PAGE_SIZE_DEFAULT = 200;
export const DEBOUNCE_MS_DEFAULT = 500;
export const ORIENTATION_LABEL = "Orientation";
export const earliestDateTZRegion = "Europe/London";
export const latestDateTZRegion = "Europe/London";
export const demographicSourceName = "route";
export const UNIT_ABBREVIATION = { METER: "(Metres)", KILOMETER: "(Km)", MILE: "(Miles)" };
export const ROUTE = "ROUTE";
export const DEFAULT_AUDIENCE_NAME = "All Adults 15+";
export const CAMPAIGN_PROBABILITIES = [0, 30, 60, 90, 100];
export const DEFAULT_AUDIENCE_QUERY = "ageband>=1";
export const GEOGRAPHY_REACH_WARNING =
  "You have not entered the relevant geography for this schedule and as a result, this will be treated as a National Reach when selecting frames and determining average Impacts.";
export const FREE_FORM_CONTAINER = "FREE_FORM_CONTAINER";
export const DDS_PRINT = { Magazine: "M", Other: "N" };
