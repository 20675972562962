import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-action-button",
  templateUrl: "./action-button.component.html",
  styleUrls: ["./action-button.component.scss"],
})
export class ActionButtonComponent {
  @Input() label: string;
  @Input() isDisabled: boolean = false;
  @Input() icon: string;
  @Input() isPrimary: boolean = true;

  get color(): string {
    return this.isPrimary ? "primary" : "secondary";
  }
}

@Component({
  selector: "app-action-buttons-container",
  template: `
    <div class="action-buttons-container">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ["./action-button.component.scss"],
})
export class ActionButtonsContainerComponent {}
