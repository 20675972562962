import { Pipe, PipeTransform } from "@angular/core";
import { shortenWithSymbolSuffix } from "src/app/home/audience-planning-tool/utils/number";

@Pipe({
  name: "shorten-with-symbol-suffix",
})
export class ShortenWithSymbolSuffixPipe implements PipeTransform {
  transform(value: number): string {
    const num = Number(value);
    if (isNaN(num)) {
      return "NaN";
    }
    return shortenWithSymbolSuffix(num, 2);
  }
}
