import mapboxgl from "mapbox-gl";
import { Column } from "grid-lib/lib/interfaces/column.interfaces";
import { TemplateRef } from "@angular/core";

const spot_id_column_name = "Spot Id";

export const Formats = {
  dateFormat: "DD/MM/YYYY",
};

export const captions = {
  frame_id: "Panel Id",
  frames: "panels",
  frame: "Panel",
  spot_id: spot_id_column_name,
  image: "Image",
  source_system: "Source System",
  mo_frame_reference: "MO Panel Reference ID",
  panel_name: "Panel Name",
  panel_code: "Panel Code",
  media_owner: "Media Owner",
  environment: "Venue Type",
  illumination_type: "Illumination Type",
  orientation: "Shape",
  format: "Product",
  formatGroup: "Product Group",
  on_route: "On Route",
  has_motion: "Digital Motion",
  coordinates: "Coordinates",
  latitude: "Latitude",
  longitude: "Longitude",
  tv_region: "Market",
  town: "Town",
  cbsa: "CA",
  city: "City",
  postcode: "Postal Code",
  postcode_sector: "Postcode Sector",
  address: "Location Description",
  height: "Height",
  width: "Width",
  size: "Size (HxW)",
  surface_area: "Surface Area",
  price: "Price",
  status: "Status",
  mo_price: "MO Price",
  nearest_poi: "POI (Nearest)",
  poi_address: "POI Address",
  poi_distance_range_bucket: "POI Distance Range",
  poi_distance_from_frame: "POI Distance",
  product: "Products",
  quantity: "Quantity",
  container_name: "Container Name",
  format_group: "Product Group",
  trading_mode: "tradingMode",
  booking_status: "bookingStatus",
  direction: "Facing",
  county: "FSA",
  country: "Country",
  state: "Province",
  prohibition_status: "Prohibition Status",
  number_of_associated_POIs: "Number of associated POIs",
  default_sot_percentage: "SOT %",
  default_spot_length: "Default spot length",
  maximum_spot_length: "Maximum spot length",
  minimum_spot_length: "Minimum spot length",
  pixel_width: "Pixel width",
  pixel_height: "Pixel height",
  impactsLabel: "Impressions",
  impactsLabelWithDefaultAudience: "Impressions (000's) All Adults",
  targetImpactsLabel: "Target Impressions",
  availabilityDateLabel: "Campaign Dates (dd/mm/yyyy)",
  impacts: "Impressions",
  environment_label: "Venue Type",
  hfss_label: "Restricted",
  lead_buyer_placeholder: "Lead Client Fulfillment",
  lead_planner_placeholder: "Lead Client Success",
  ffcMainHeader: "Add Container",
  ffcHeaderIcon: "'../../../../../assets/images/plus-icon.png",
  inventoryType: "Media Type",
  audience_index: "Audience Index",
  audience_on_target_percentage: "Audience On Target %",
  source_system_frame_id: "Source System Frame ID",
};

export const platoMapLibcaptions = {
  mo_frame_reference: captions.mo_frame_reference,
  environment: captions.environment,
  format: captions.format,
  postcode: captions.postcode,
  impacts: captions.impacts,
};

export const supportsPrintMedia = true;

export const showRateType = true;

export const showCycleType = true;

export const showReferences = true;

export const frameColorCoding = true;

export const framesLabelName = true;

export const enableSearchBySpotId = true;

export const loadDatesInFormatMenu = false;

export const enableProhibition = false;

export const mapViewControl = false;

export const addFrameDialogComfirmation = true;

export const isTradingTypeCPTByDefault = false;

export const isGroupingConfigV3US = true;

export const isGroupingConfigV3 = false;

export const countryDropDown = [
  { name: "Canada", value: "CAN" },
  { name: "US", value: "US" },
];

export const setFunctionsOfSpotIdBasedOnRegion = {
  sortFrameSpotId: false,
  setSpotIdToFrameId: false,
};

export const panelListOption = {
  geopathOption: false,
  routeOption: false,
  motionOption: false,
};

export const frameLabelOnNavbar = {
  framePlaceholder: "Frame IDs / Spot IDs (Enter / Paste here)",
};

export const ShareListingPageCaptions = {
  campaignName: "Campaign Name",
  client: "Client",
  createdOn: "Created On",
  agency: "Agency",
  action: "Action",
  linkLabel: "LinkLabel",
  getLink: "Get Link",
  deleteLabel: "DeleteLabel",
  deleteLink: "Delete Link",
  linkCopiedMessage: "Link copied to clipboard!",
  searchAgencyPlaceHolder: "Search Agency",
  searchClientPlaceHolder: "Search Client",
  shareResultTooltip: "Share results",
};

export const filterCaptions = {
  environment: "Venue Type",
  format: "Product",
  media_owner: "Media Owner",
  prohibition_status: "Prohibition Status",
};

export const excludedExportColumns = [spot_id_column_name];

export const CampaignBudgetDetailsFields = {
  totalBudgetNet: "Total Client Cost",
  FixedCostBudget: "Total Client Fixed Cost",
  mediaBudgetNet: "Total Client Media Cost",
};

export const CampaignGridDropDownOptions = {
  option: "Hold",
  prepareToOption: "Request Hold",
};

export const AvgImpression = {
  avgImpressionField: "Avg. 4 wk Impressions",
};
export const printMediaFFCFields = {
  venue: "Venue",
  publisher: "Publisher",
  publication: "Publication",
  placement: "Placement",
  mediaSupplier: "Media Supplier",
  adSize: "Ad Size",
  dateOfPublication: "Date of Publication",
  circulation: "Circulation",
  quantity: "Quantity",
  printKeycode: "Version Code",
  geography: "Geography",
  ddsPunblicationCode: "DDS Publication Code",
  ddsPunblicationCodeMarket: "DDS Publication Code Market",
  ddsPrint: "DDS Print",
  mediaOceanUpload: "Media Ocean Upload",
  notesOfPublisher: "Notes Of Publisher",
  spaceDescription: "Space Description",
};

export const venueDropdownListConfig = [
  "Newspaper (ROP)",
  "Insert",
  "Direct Mail",
  "Magazine",
  "Digital Newspaper",
  "Non Media",
];

export const mediaOceanUploadDropdown = ["true", "false"];

export const CampaignActiveColumns = [
  "actions",
  "buyCost",
  "dates",
  "customerReference",
  "dma",
  "environment",
  "fixedCosts",
  "formatName",
  "frameId",
  "mediaOwnerName",
  "mediaType",
  "mediaOwnerFrameReference",
  "name",
  "installationCost",
  "productionCost",
  "groupCount",
  "bookingStatus",
  "sellCost",
];

export const LongLat: mapboxgl.LngLatLike = [-96.6930257, 38.8951];

export const GetOuterColumnsV3 = (
  cellTemplates: { [key: string]: TemplateRef<unknown> },
  footerTemplates: { [key: string]: TemplateRef<unknown> },
  feeCalculationType: string
) => {
  return [
    {
      field: "mediaType",
      header: "CHANNEL",
      width: 65,
      isSortable: true,
      cellTemplate: cellTemplates["mediaType"],
    },
    {
      field: "unitRationale",
      header: "UNIT RATIONALE",
      isSortable: false,
      width: 90,
      cellTemplate: cellTemplates["unitRationale"],
    },
    {
      field: "frameId",
      header: "Panel ID",
      width: 65,
    },
    {
      field: "mediaOwnerFrameReference",
      header: "MO PANEL REFERENCE",
      width: 90,
      cellTemplate: cellTemplates["moFrameReference"],
    },
    {
      field: "dma",
      header: "MARKET",
      width: 100,
      isSortable: true,
      cellTemplate: cellTemplates["dma"],
    },
    {
      field: "name",
      header: "LOCATION DESCRIPTION",
      width: 100,
      isSortable: true,
      cellTemplate: cellTemplates["name"],
    },
    {
      field: "orderId",
      header: "ORDER ID",
      width: 65,
      cellTemplate: cellTemplates["orderId"],
    },
    {
      field: "orderLineId",
      header: "ORDERLINE ID",
      width: 65,
      cellTemplate: cellTemplates["orderLineId"],
    },
    {
      field: "lineNumber",
      header: "IO",
      width: 65,
      isSortable: true,
      cellTemplate: cellTemplates["lineNumber"],
    },
    {
      field: "formatName",
      header: "PRODUCT",
      isSortable: true,
      width: 80,
    },
    {
      header: "VENUE TYPE",
      cellTemplate: cellTemplates["environment"],
      width: 95,
    },
    {
      field: "mediaOwnerName",
      header: "MEDIA OWNER",
      width: 80,
      isSortable: true,
      cellTemplate: cellTemplates["mediaOwnerName"],
    },
    {
      field: "billingUnit",
      header: "BILLING UNIT",
      width: 80,
      isSortable: true,
      cellTemplate: cellTemplates["billingUnit"],
    },
    {
      header: "DATES",
      cellTemplate: cellTemplates["commonSchedule"],
      footerTemplate: footerTemplates["totalLabel"],
      isSortable: true,
      width: 80,
    },
    {
      field: "dayPartDescription",
      header: "DAY PART",
      width: 85,
      cellTemplate: cellTemplates["dayPartDescription"],
    },
    {
      field: "daysOfWeek",
      header: "Days of Week",
      width: 88,
      cellTemplate: cellTemplates["daysOfWeek"],
    },
    {
      field: "buyTypes",
      header: "Buy Type",
      width: 80,
      cellTemplate: cellTemplates["buyTypes"],
    },
    {
      field: "geographyAuditData",
      header: "Geography",
      width: 80,
      cellTemplate: cellTemplates["geographyAudit"],
    },
    {
      field: "shareOfTime",
      header: "SOT",
      width: 65,
      cellTemplate: cellTemplates["shareOfTime"],
    },
    {
      field: "groupCount",
      header: "QTY",
      isSortable: true,
      width: 50,
      footerTemplate: footerTemplates["groupCount"],
      cellTemplate: cellTemplates["editQuantity"],
    },
    {
      field: "buyCurrency",
      header: "Buy Currency",
      width: 90,
      cellTemplate: cellTemplates["buyCurrency"],
    },
    {
      field: "exchangeMultiplier",
      header: "Exchange Multiplier",
      width: 90,
      cellTemplate: cellTemplates["exchangeMultiplier"],
    },
    {
      field: "plannedCost",
      header: "PLANNED PRICE/UP",
      isSortable: true,
      cellTemplate: cellTemplates["plannedCost"],
      footerTemplate: footerTemplates["plannedCost"],
      width: 90,
    },
    {
      field: "sellCost",
      header: "SELL COST",
      isSortable: true,
      cellTemplate: cellTemplates["sellCost"],
      footerTemplate: footerTemplates["sellCost"],
      width: 90,
    },
    ...showFeePercentageColumn(feeCalculationType, cellTemplates["feePercentage"]),
    {
      field: "buyCost",
      header: "BUY COST",
      isSortable: true,
      cellTemplate: cellTemplates["buyCost"],
      footerTemplate: footerTemplates["buyCost"],
      width: 90,
    },
    {
      field: "discountPercentage",
      header: "DISCOUNT %",
      isSortable: true,
      cellTemplate: cellTemplates["discountPercentage"],
      footerTemplate: footerTemplates["discountPercentage"],
      width: 90,
    },
    {
      field: "mediaOwnerPrice",
      header: "MO PRICE/UP",
      isSortable: true,
      cellTemplate: cellTemplates["mediaOwnerPrice"],
      footerTemplate: footerTemplates["mediaOwnerPrice"],
      width: 90,
    },
    {
      field: "fixedCosts",
      header: "FIXED COSTS",
      isSortable: true,
      cellTemplate: cellTemplates["fixedCosts"],
      footerTemplate: footerTemplates["fixedCosts"],
      width: 90,
    },
    {
      field: "cancellationCost",
      header: "CANCELLATION COST",
      cellTemplate: cellTemplates["cancellationCost"],
      footerTemplate: footerTemplates["cancellationCost"],
      width: 95,
    },
    {
      field: "productionCost",
      header: "PRODUCTION COST",
      isSortable: false,
      cellTemplate: cellTemplates["productionCostTemplate"],
      footerTemplate: footerTemplates["productionCosts"],
      width: 90,
    },
    {
      field: "installationCost",
      header: "Install / Digital Trafficking",
      isSortable: false,
      cellTemplate: cellTemplates["installationCostTemplate"],
      footerTemplate: footerTemplates["installationCosts"],
      width: 90,
    },
    {
      field: "customerReference",
      header: "CUSTOMER REFERENCE",
      isSortable: false,
      cellTemplate: cellTemplates["customerReference"],
      width: 90,
    },
    {
      field: "availability",
      header: "AVAILS",
      width: 80,
      cellTemplate: cellTemplates["availability"],
    },
    {
      field: "financeFlag",
      header: "SKIP FINANCE PROCESSING",
      width: 80,
      cellTemplate: cellTemplates["financeFlag"],
    },
    {
      field: "actions",
      header: "ACTIONS",
      width: 180,
      cellTemplate: cellTemplates["actions"],
      footerTemplate: footerTemplates["actions"],
    },
    {
      field: "contractingStatus",
      header: "CONTRACT STATUS",
      width: 140,
      isSortable: true,
      cellTemplate: cellTemplates["contractingStatus"],
    },
    {
      field: "bookingStatus",
      header: "REQUEST STATUS",
      width: 115,
      isSortable: true,
      cellTemplate: cellTemplates["bookingStatus"],
    },
  ] as Array<Column>;
};

export const GetInnerColumnsV3 = () => {
  return [] as Array<Column>;
};

export const GetAgencyOuterColumnsV3 = (
  cellTemplates: { [key: string]: TemplateRef<any> },
  footerTemplates: { [key: string]: TemplateRef<any> }
) => {
  return [
    {
      field: "mediaType",
      header: "CHANNEL",
      width: 90,
      cellTemplate: cellTemplates["mediaType"],
    },
    {
      field: "name",
      header: "NAME",
      width: 120,
      cellTemplate: cellTemplates["name"],
    },
    {
      field: "formatName",
      header: "PRODUCT",
      width: 100,
    },
    {
      header: "VENUE TYPE",
      cellTemplate: cellTemplates["environment"],
      width: 125,
    },
    {
      field: "mediaOwnerName",
      header: "MEDIA OWNER",
      width: 100,
      cellTemplate: cellTemplates["mediaOwnerName"],
    },
    {
      header: "SCHEDULE",
      cellTemplate: cellTemplates["commonSchedule"],
      footerTemplate: footerTemplates["totalLabel"],
      width: 105,
    },
    {
      header: "DUE DATE",
      cellTemplate: cellTemplates["mediaOwnerName"],
      footerTemplate: footerTemplates["totalLabel"],
      width: 100,
    },
  ] as Array<Column>;
};

function showFeePercentageColumn(feeCalculationType: string, cellTemplate: TemplateRef<any>): Column[] {
  const columnTobeAdded: Column[] = [];
  if (feeCalculationType != null) {
    columnTobeAdded.push({
      field: "feePercentage",
      header: feeCalculationType,
      width: 70,
      cellTemplate: cellTemplate,
    });
  }
  return columnTobeAdded;
}
