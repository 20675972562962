import mapboxgl from "mapbox-gl";
import { TemplateRef } from "@angular/core";
import { Column } from "grid-lib/lib/interfaces/column.interfaces";

export const Formats = {
  dateFormat: "DD/MM/YYYY",
};

export const captions = {
  frame_id: "Frame Id",
  frames: "frames",
  frame: "Frame",
  panel_name: "Panel Name",
  panel_code: "Panel Code",
  media_owner: "Media Owner",
  mo_frame_reference: "MO Frame Reference ID",
  environment: "Environment Name",
  illumination_type: "Illumination Type",
  orientation: "Orientation",
  format: "Format Name",
  formatGroup: "Format Group",
  on_route: "On Route",
  has_motion: "Has Motion",
  face_count: "Face Count",
  face_type: "Face Type",
  coordinates: "Coordinates",
  tv_region: "TV Region",
  town: "Town",
  conurbation: "Conurbation",
  country: "Country",
  postcode: "Postcode",
  postcode_sector: "Postcode Sector",
  address: "Address",
  street_name: "Street Name",
  locality: "Locality",
  administrative_area: "Administrative Area",
  height: "Height",
  width: "Width",
  surface_area: "Surface Area",
  site_code: "Site Code",
  impacts: "Impacts",
  availability: "Availability",
  price: "Price",
  status: "Status",
  mo_price: "MO Price",
  nearest_poi: "POI (Nearest)",
  poi_address: "POI Address",
  poi_distance_range_bucket: "POI Distance Range",
  poi_distance_from_frame: "POI Distance",
  product: "Products",
  quantity: "Quantity",
  container_name: "Container Name",
  format_group: "Format Group",
  trading_mode: "tradingMode",
  booking_status: "bookingStatus",
  default_sot_percentage: "SOT %",
  impactsLabel: "Impacts (000's)",
  impactsLabelWithDefaultAudience: "Impacts (000's) All Adults",
  targetImpactsLabel: "Target Impacts",
  availabilityDateLabel: "Availability (dd/mm/yyyy)",
  environment_label: "Environment",
  hfss_label: "HFSS (High in fat, sugar and salt)",
  lead_buyer_placeholder: "Lead Buyer",
  lead_planner_placeholder: "Lead Planner",
  ffcMainHeader: "Add Free Form Container Schedule",
  ffcHeaderIcon: "'../../../../../assets/images/free-form-container.svg",
  inventoryType: "Inventory Type",
  audience_index: "Audience Index",
  audience_on_target_percentage: "Audience On Target %",
  source_system_frame_id: "Source System Frame ID",
};

export const platoMapLibcaptions = {
  mo_frame_reference: captions.mo_frame_reference,
  environment: captions.environment,
  format: captions.format,
  postcode: captions.postcode,
  impacts: captions.impacts,
};

export const shareListingPageCaptions = {
  campaignName: "Campaign Name",
  client: "Client",
  createdOn: "Created On",
  agency: "Agency",
  action: "Action",
  linkLabel: "LinkLabel",
  getLink: "Get Link",
  deleteLabel: "DeleteLabel",
  deleteLink: "Delete Link",
  linkCopiedMessage: "Link copied to clipboard!",
  searchAgencyPlaceHolder: "Search Agency",
  searchClientPlaceHolder: "Search Client",
  shareResultTooltip: "Share results",
};

export const filterCaptions = {
  environment: "Environment",
  format: "Format",
  media_owner: "Media Owner",
};

export const CampaignBudgetDetailsFields = {
  totalBudgetNet: "Total Budget Net",
  FixedCostBudget: "Fixed Cost Budget Net",
  mediaBudgetNet: "Media Budget Net",
};

export const CampaignGridDropDownOptions = {
  option: "Option",
  prepareToOption: "Prepare to Option",
};

export const AvgImpression = {
  avgImpressionField: "Avg. Impressions",
};

export const supportsPrintMedia = false;

export const frameColorCoding = false;

export const frameLabelOnNavbar = {
  framePlaceholder: "Frame IDs (Enter / Paste here)",
};

export const countryDropDown = [
  { name: "IE", value: "IE" },
  { name: "UK", value: "UK" },
];

export const enableSearchBySpotId = false;

export const loadDatesInFormatMenu = true;

export const enableProhibition = false;

export const mapViewControl = true;

export const addFrameDialogComfirmation = false;

export const isTradingTypeCPTByDefault = true;

export const isGroupingConfigV3US = false;

export const isGroupingConfigV3 = true;

export const setFunctionsOfSpotIdBasedOnRegion = {
  sortFrameSpotId: false,
  setSpotIdToFrameId: false,
};

export const panelListOption = {
  geopathOption: false,
  routeOption: true,
  motionOption: true,
};

export const CampaignActiveColumns = [
  "actions",
  "buyCost",
  "customerReference",
  "mediaType",
  "environment",
  "formatName",
  "mediaOwnerName",
  "mediaOwnerPrice",
  "name",
  "plannedCost",
  "groupCount",
  "bookingStatus",
  "schedule",
  "sellCost",
  "days",
];

export const LongLat: mapboxgl.LngLatLike = [-1.962073, 53.750832];

export const GetOuterColumnsV3 = (
  cellTemplates: { [key: string]: TemplateRef<any> },
  footerTemplates: { [key: string]: TemplateRef<any> }
) => {
  return [
    {
      field: "mediaType",
      header: "CHANNEL",
      width: 85,
      isSortable: true,
      cellTemplate: cellTemplates["mediaType"],
    },
    {
      field: "unitRationale",
      header: "UNIT RATIONALE",
      isSortable: false,
      width: 90,
      cellTemplate: cellTemplates["unitRationale"],
    },
    {
      field: "name",
      header: "NAME",
      width: 100,
      cellTemplate: cellTemplates["name"],
    },
    {
      field: "orderId",
      header: "ORDER ID",
      width: 65,
      cellTemplate: cellTemplates["orderId"],
    },
    {
      field: "orderLineId",
      header: "ORDERLINE ID",
      width: 65,
      cellTemplate: cellTemplates["orderLineId"],
    },
    {
      field: "lineNumber",
      header: "IO",
      width: 65,
      isSortable: true,
      cellTemplate: cellTemplates["lineNumber"],
    },
    {
      field: "formatName",
      header: "FORMAT",
      width: 80,
      isSortable: true,
    },
    {
      header: "ENVIRONMENT",
      cellTemplate: cellTemplates["environment"],
      width: 124,
      isSortable: true,
    },
    {
      field: "mediaOwnerName",
      header: "MEDIA OWNER",
      width: 110,
      isSortable: true,
      cellTemplate: cellTemplates["mediaOwnerName"],
    },
    {
      header: "SCHEDULE",
      cellTemplate: cellTemplates["commonSchedule"],
      footerTemplate: footerTemplates["totalLabel"],
      width: 90,
      isSortable: true,
    },
    {
      field: "days",
      header: "Days",
      width: 60,
      cellTemplate: cellTemplates["days"],
    },
    {
      filed: "dayPartDescription",
      header: "DAY PART",
      width: 85,
      cellTemplate: cellTemplates["dayPartDescription"],
    },
    {
      field: "daysOfWeek",
      header: "Days of Week",
      width: 88,
      cellTemplate: cellTemplates["daysOfWeek"],
    },
    {
      field: "buyType",
      header: "Buy Type",
      width: 80,
      cellTemplate: cellTemplates["buyType"],
    },
    {
      field: "reach",
      header: "Reach",
      width: 80,
      cellTemplate: cellTemplates["reach"],
    },
    {
      field: "geographyAuditData",
      header: "Geography",
      width: 80,
      cellTemplate: cellTemplates["geographyAudit"],
    },
    {
      field: "shareOfTime",
      header: "SOT",
      width: 50,
      cellTemplate: cellTemplates["shareOfTime"],
    },
    {
      field: "groupCount",
      header: "QTY",
      width: 50,
      footerTemplate: footerTemplates["groupCount"],
      cellTemplate: cellTemplates["editQuantity"],
    },
    {
      field: "impacts",
      header: "Impacts (000's) All Adults",
      width: 95,
      cellTemplate: cellTemplates["impacts"],
      footerTemplate: footerTemplates["impacts"],
    },
    {
      field: "buyCurrency",
      header: "Buy Currency",
      width: 90,
      cellTemplate: cellTemplates["buyCurrency"],
    },
    {
      field: "exchangeMultiplier",
      header: "Exchange Multiplier",
      width: 90,
      cellTemplate: cellTemplates["exchangeMultiplier"],
    },
    {
      field: "plannedCost",
      header: "PLANNED PRICE/UP",
      cellTemplate: cellTemplates["plannedCost"],
      footerTemplate: footerTemplates["plannedCost"],
      width: 120,
    },
    {
      field: "sellCost",
      header: "SELL COST/UP",
      cellTemplate: cellTemplates["sellCost"],
      footerTemplate: footerTemplates["sellCost"],
      width: 90,
    },
    {
      field: "buyCost",
      header: "BUY COST/UP",
      cellTemplate: cellTemplates["buyCost"],
      footerTemplate: footerTemplates["buyCost"],
      width: 90,
    },
    {
      field: "mediaOwnerPrice",
      header: "MO PRICE/UP",
      cellTemplate: cellTemplates["mediaOwnerPrice"],
      footerTemplate: footerTemplates["mediaOwnerPrice"],
      width: 90,
    },
    {
      field: "prePaymentPercentage",
      header: "PREPAYMENT",
      cellTemplate: cellTemplates["prePayment"],
      width: 90,
    },
    {
      field: "fixedCosts",
      header: "FIXED COSTS",
      cellTemplate: cellTemplates["fixedCosts"],
      footerTemplate: footerTemplates["fixedCosts"],
      width: 90,
    },
    {
      field: "cancellationCost",
      header: "CANCELLATION COST",
      cellTemplate: cellTemplates["cancellationCost"],
      footerTemplate: footerTemplates["cancellationCost"],
      width: 95,
    },
    {
      field: "customerReference",
      header: "CUSTOMER REFERENCE",
      isSortable: false,
      cellTemplate: cellTemplates["customerReference"],
      width: 90,
    },
    {
      field: "availability",
      header: "AVAILS",
      width: 80,
      cellTemplate: cellTemplates["availability"],
    },
    {
      field: "financeFlag",
      header: "SKIP FINANCE PROCESSING",
      width: 80,
      cellTemplate: cellTemplates["financeFlag"],
    },
    {
      field: "actions",
      header: "ACTIONS",
      width: 140,
      cellTemplate: cellTemplates["actions"],
      footerTemplate: footerTemplates["actions"],
    },
    {
      field: "bookingStatus",
      header: "REQUEST STATUS",
      width: 115,
      cellTemplate: cellTemplates["bookingStatus"],
    },
  ] as Column[];
};

export const GetInnerColumnsV3 = () => {
  return [] as Column[];
};

export const GetAgencyOuterColumnsV3 = (
  cellTemplates: { [key: string]: TemplateRef<any> },
  footerTemplates: { [key: string]: TemplateRef<any> }
) => {
  return [
    {
      field: "mediaType",
      header: "CHANNEL",
      width: 90,
      cellTemplate: cellTemplates["mediaType"],
    },
    {
      field: "name",
      header: "NAME",
      width: 120,
      cellTemplate: cellTemplates["name"],
    },
    {
      field: "formatName",
      header: "FORMAT",
      width: 100,
    },
    {
      header: "ENVIRONMENT",
      cellTemplate: cellTemplates["environment"],
      width: 125,
    },
    {
      field: "mediaOwnerName",
      header: "MEDIA OWNER",
      width: 100,
      cellTemplate: cellTemplates["mediaOwnerName"],
    },
    {
      header: "SCHEDULE",
      cellTemplate: cellTemplates["commonSchedule"],
      footerTemplate: footerTemplates["totalLabel"],
      width: 105,
    },
    {
      header: "DUE DATE",
      cellTemplate: cellTemplates["mediaOwnerName"],
      footerTemplate: footerTemplates["totalLabel"],
      width: 100,
    },
  ] as Column[];
};

export const showRateType = false;

export const showCycleType = false;

export const showReferences = false;

export const ShareListingPageCaptions = {
  campaignName: "Campaign Name",
  client: "Client",
  createdOn: "Created On",
  agency: "Agency",
  action: "Action",
  linkLabel: "LinkLabel",
  getLink: "Get Link",
  deleteLabel: "DeleteLabel",
  deleteLink: "Delete Link",
  linkCopiedMessage: "Link copied to clipboard!",
  searchAgencyPlaceHolder: "Search Agency",
  searchClientPlaceHolder: "Search Client",
  shareResultTooltip: "Share results",
};
