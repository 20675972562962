import { Inject, Injectable, InjectionToken, Optional } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { ConfigurationService } from "src/app/core/svc/configuration-service";
import { Observable } from "rxjs";
import { debounce } from "lodash";

const environment = new ConfigurationService().getConfig();
const DEFAULT_BASE_URL = new InjectionToken<string>("default-base-url", {
  providedIn: "root",
  factory: () => "",
});

@Injectable({ providedIn: "root" })
export class ApiService {
  constructor(private httpClient: HttpClient, @Optional() @Inject(DEFAULT_BASE_URL) private baseUrl: string = "") {}

  get<T>(endpoint: string): Observable<any> {
    return this.makeRequest<T>("GET", this.buildUrl(endpoint));
  }

  post<T>(endpoint: string, object: T, headers?: any): Observable<any> {
    return this.makeRequest<T>("POST", this.buildUrl(endpoint), object, headers);
  }

  put<T>(endpoint: string, objectUpdated: T): Observable<any> {
    return this.makeRequest<T>("PUT", this.buildUrl(endpoint), objectUpdated);
  }

  patch<T>(endpoint: string, objectUpdated: T): Observable<any> {
    return this.makeRequest<T>("PATCH", this.buildUrl(endpoint), objectUpdated);
  }

  delete<T>(endpoint: string, objectUpdated?: T): Observable<any> {
    return this.makeRequest<T>("DELETE", this.buildUrl(endpoint), objectUpdated);
  }

  makeRequest<T>(method: string, endpoint: string, body?: any, headers?: any): Observable<any> {
    return this.httpClient.request<T>(method, this.constructFullyQualifiedURL(endpoint), {
      body,
      headers,
    });
  }

  makeRequestWithOptions(method: string, endpoint: string, body: any, options: any): Observable<any> {
    options.body = body;
    return this.httpClient.request(method, this.constructFullyQualifiedURL(endpoint), options);
  }

  // eslint-disable-next-line no-use-before-define
  makeRequestWithFullyQualifiedURLAndOptions(
    method: string,
    fullyQualifiedURL: string,
    body: any,
    options: any
  ): Observable<any> {
    options.body = body;
    return this.httpClient.request(method, fullyQualifiedURL, options);
  }

  public constructFullyQualifiedURL(endpoint: string): string {
    if (endpoint.indexOf("http://localhost") < 0) {
      endpoint = environment.apiUrl + endpoint;
    }
    return this.addLocaleQueryParam(endpoint);
  }

  public addLocaleQueryParam(endpoint: string): string {
    const urlObject = new URL(endpoint, "http://dummyhost");
    const hasQueryParams: boolean = urlObject.search.length > 0;
    if (hasQueryParams) {
      return this.addLocaleToURLWithoutQueryParams(endpoint);
    } else {
      return this.addLocaleToURLHavingQueryParams(endpoint);
    }
  }

  public handleBlobResponsError(error: HttpErrorResponse): Promise<string> {
    if (error.error instanceof Blob) {
      return this.readBlobAsText(error.error).then((text) => {
        try {
          const jsonResponse = JSON.parse(text);
          return Promise.reject(jsonResponse); // Reject with parsed JSON
        } catch (e) {
          return Promise.reject("Failed to parse error response"); // Handle parsing error
        }
      });
    }
    return Promise.reject("An unknown error occurred"); // Fallback for non-blob errors
  }

  private readBlobAsText(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader(); // Create a new FileReader instance

      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = () => {
        reject("Could not read blob"); // Reject the promise with an error message
      };
      reader.readAsText(blob);
    });
  }

  private addLocaleToURLWithoutQueryParams(endpoint: string): string {
    if (endpoint.endsWith("&")) {
      return endpoint + `locale=${environment.locale}`;
    }
    return endpoint + `&locale=${environment.locale}`;
  }

  private addLocaleToURLHavingQueryParams(endpoint: string): string {
    if (endpoint.endsWith("?")) {
      return endpoint + `locale=${environment.locale}`;
    }
    return endpoint + `?locale=${environment.locale}`;
  }

  private buildUrl(endpoint: string): string {
    return `${this.baseUrl || ""}${endpoint}`;
  }
}

/**
 * Debounce a method
 */
export function Debounce(ms) {
  return function (target: any, key: any, descriptor: any) {
    const oldFunc = descriptor.value;
    const newFunc = debounce(oldFunc, ms);
    descriptor.value = function () {
      return newFunc.apply(this, arguments);
    };
  };
}

export function readFileNameFromHeaders(response: HttpResponse<Blob>, fallbackName: string): string {
  let fileName = fallbackName;
  const contentDisposition = response.headers?.get("Content-Disposition");
  if (contentDisposition) {
    const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = fileNameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, "");
    }
  }
  return fileName;
}

export function getErrorMessageFromResponse(resp: any): string {
  if (resp?.error?.subErrors?.length > 0) {
    return resp.error.subErrors.map((e) => e.message).join(" \n");
  } else if (isForbiddenError(resp)) {
    return "You are not authorized to perform this action";
  } else return resp?.error?.message || resp?.error?.msg || resp?.error || resp;
}

export function isForbiddenError(resp: any): boolean {
  return resp?.status === 403;
}
