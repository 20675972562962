<div class="notes">
  <div class="notes-header">
    <h5>Edit Note</h5>
  </div>
  <div class="notes-content">
    <form class="form-sec" (ngSubmit)="saveNote()">
      <div class="col-12 p-0">
        <textarea [(ngModel)]="noteContent" class="form-control" name="note"></textarea>
      </div>
      <button type="submit" class="primary-btn pull-left" (mousedown)="handleMouseDown($event)">Save</button>
    </form>
  </div>
</div>
