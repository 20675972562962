/* tslint:disable:member-ordering */
import { Injectable } from "@angular/core";
import { ApiService } from "./api/api.service";
import { PersistenceService } from "./persistence/persistence.service";
import { JwtHelper } from "./JwtHelper";
import { EMPTY, of, ReplaySubject, Subject } from "rxjs";
import { catchError, delay, distinctUntilChanged, filter, flatMap, map, switchMap } from "rxjs/operators";
import { Store } from "./persistence/store";
import { AuthorizationInfo, claimKeys } from "./authorization-info";
import { Router } from "@angular/router";
import { scheduleMacroTask } from "../common/utils";
import { AuthService } from "@auth0/auth0-angular";

@Injectable({
  providedIn: "root",
})
export class AuthorizationService {
  get loggedIn$() {
    return this.auth.isAuthenticated$;
  }

  get accessToken$() {
    return this.auth.getAccessTokenSilently();
  }

  logout() {
    this.auth.logout({ logoutParams: { returnTo: window.location.origin } });
    this.router.navigate(["/"]);
  }

  get authorities$() {
    return this.auth.user$
      .pipe(filter((user) => user != null))
      .pipe(
        map((user) => {
          return user[claimKeys.namespace][claimKeys.authorities];
        })
      )
      .pipe(distinctUntilChanged());
  }

  // TODO: Refactor all usages to refer to this
  get authenticatedUserDetails$() {
    return this.auth.user$
      .pipe(catchError((_) => of({ userId: "", email: "", authorities: [] })))
      .pipe(filter((user) => user != null))
      .pipe(
        map((user) => ({
          userId: user[claimKeys.namespace][claimKeys.user_name],
          email: user.email,
          authorities: user[claimKeys.namespace][claimKeys.authorities],
        }))
      )
      .pipe(distinctUntilChanged());
  }

  get userName$() {
    return this.auth.user$
      .pipe(catchError((_) => of("")))
      .pipe(filter((user) => user != null))
      .pipe(map((user) => user[claimKeys.namespace][claimKeys.user_name]))
      .pipe(distinctUntilChanged());
  }

  get email$() {
    return this.auth.user$.pipe(map((user) => user.email));
  }

  private fetchingToken = false;

  public loginAuth0() {
    console.log("Will loginWithRedirect");
    this.auth.loginWithRedirect();
  }

  constructor(private auth: AuthService, private router: Router) {
    console.log("Auth constructor loaded..");
    this.loginIfNotAuthenticated();
  }

  private loginIfNotAuthenticated() {
    console.log("loginIfNotAuthenticated...");
    if (!this.fetchingToken) {
      this.fetchingToken = true;
      console.log("Attempting getAccessTokenSilently");
      this.auth.getAccessTokenSilently({ detailedResponse: true }).subscribe(
        (result) => {
          console.log("got Access Token Silently ", result !== undefined);
          this.fetchingToken = false;
          // this.setApplicableNavigationBasedOnResponse(result);
        },
        (e) => {
          this.fetchingToken = false;
          console.log("User not logged in", e);
          this.auth.loginWithRedirect();
        }
      );
    }
  }
}
