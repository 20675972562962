import { Observable, Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { tap } from "rxjs/operators";
import { hex } from "./common/utils";

@Injectable()
export class TraceparentInterceptor implements HttpInterceptor {
  private error$ = new Subject<{ status: number }>();

  private parentLength = 16;
  private traceLength = 32;

  private transform(request: HttpRequest<any>) {
    const parentId = hex(this.parentLength);
    const traceId = hex(this.traceLength);
    return request.clone({
      setHeaders: { traceparent: "00-" + traceId + "-" + parentId + "-01" },
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.transform(request)).pipe(
      tap(
        () => {},
        (error) => this.error$.next(error)
      )
    );
  }
}
