import { CacheLocation } from "@auth0/auth0-spa-js";

const cacheLocationImpl: CacheLocation = "localstorage" as CacheLocation;

export const getEnvironment = (baseUrl: string) => ({
  baseUrl,
  production: false,
  client_id: "talon_web_client",
  client_secret: "zsy6]cqzvqnZgJYkFXvpyJGMbN",
  auth0: {
    domain: "auth-test.plato2.talonoutdoor.com",
    clientId: "j6qvyu74803jD3vs7Q3PE8LkYYQTbcre",
    cacheLocation: cacheLocationImpl,
    authorizationParams: {
      audience: "https://plato.talonoutdoor.com/api/",
      redirect_uri: "https://qa.plato2.talonoutdoor.com",
    },
  },
  aptBypass: false,
  mapBoxTilesUrl: "mapbox://styles/talonodmp/cjvxd5f925v6g1cs8f4cvxrwd",
  accessTokenMapBox: "pk.eyJ1IjoidGFsb25vZG1wIiwiYSI6ImNqb2U2bWZlcDFsdHEzb253b2Vodnd3amUifQ.8ST92gdUUWdQROf8d7mHpA",
  envName: "qa",
  userRoleByRegion: "ENVIRONMENT_UK_QA",
  supportedRegions: ["UK", "IE"],
  defaultRegion: "UK",
  decodedAccessTokenKey: "https://plato.talonoutdoor.com",
  playoutReporting: {
    campaignBaseUrl: "https://uk-qa.pop-test.talonoutdoor.com/playout-reporting/campaigns",
    apiUrl: "https://uk-qa.pop-test.talonoutdoor.com/api/playoutreports",
  },
});
