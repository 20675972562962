export const shortenWithSymbolSuffix = (num: number, digits: number) => {
  /*
		Inputs						Outputs
		(1234, 1)					1.2K
		(1234, 0)					1K
		(1500, 0)					2K
		(1234567, 3)				1.235M
		(1000000, 0)				1M
		(10000000, 0) 				10M
		(100000000, 0) 				0.1G
		(1000000000, 0) 			1G
		(10000000000, 0)			10G
		(100000000000, 0)			0.1T
		...
		(1000000000000000000, 3)	1E
		K - Thousand 1,000
		M - Million 1,000,000
		G - Thousand Million 1,000,000,000
		T - Billion 1,000,000,000,000
		P - Thousand Billion  1,000,000,000,000,000
		E - Trillion 1,000,000,000,000,000,000
	*/
  let x = ("" + num).length;
  digits = Math.pow(10, digits);
  x -= x % 3;
  return Math.round((num * digits) / Math.pow(10, x)) / digits + " KMGTPE"[x / 3];
};

export const formatNumber = (number: number | string) => Number(number).toLocaleString() || "0";

export const sortFormattedNumbers = (number: string, nextNumber: string) => {
  const unformattedNumber = Number(number.replace(/[,%\s]/g, ""));
  const unformattedNextNumber = Number(nextNumber.replace(/[,%\s]/g, ""));
  if (unformattedNextNumber > unformattedNumber) {
    return -1;
  } else if (unformattedNextNumber < unformattedNumber) {
    return 1;
  }
  return 0;
};

export const randomPercentage = () => Math.floor(Math.random() * 100) + 1;
